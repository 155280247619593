import axios from 'axios'
import {BASE_URL} from '../api'

axios.interceptors.request.use(config => {
    const userStr = sessionStorage.getItem('user');
    if (userStr) {
        const user = JSON.parse(userStr)
        config.headers.common['Authorization'] = user.secure.token
    }
    return config
}, err => {
    console.log(err)
    return Promise.reject(err)
})

// axios.interceptors.response.use(response => {
//     const {options = {}} = response
//     !options.hideLoading && loading && loading.hide()
//     return response
// }, err => {
//     loading && loading.visible && loading.hide()
//     return Promise.reject(err.response.status)
// })

function sendRequest(params = {}, options = {}) {
    let _resolve = null
    let _reject = null
    const promise = new Promise((resolve, reject) => {
        _resolve = resolve
        _reject = reject
    })
    const {url, method, data = {}} = Object.assign({}, params, options)
    // const date = new Date()
    // const accessSecretConfig = {
    //     httpMethod: method,
    //     urlPath: url,
    //     date
    // }
    axios({
        url: BASE_URL + url,
        method,
        data: method.toLowerCase() === 'post' ? data : null,
        params: method.toLowerCase() === 'get' ? data : null,
        // headers: {
        //     'cxy-security-version': '1.0.0'
        // },
        options
    }).then(response => {
        const result = response.data || {}
        _resolve(result);
        // if (+code === 0) {}
        // // eslint-disable-next-line no-prototype-builtins
        // if (result.hasOwnProperty('respCode')) {
        //     const {message, respCode, data: responseData} = result
        //     if (+respCode === 0) {
        //         _resolve(responseData || {})
        //     } else {
        //         Toast.fail(message || '请求失败')
        //         _reject(result)
        //     }
        // } else {
        //     const {success, msg, obj} = result
        //     if (success) {
        //         _resolve(obj || {})
        //     } else {
        //         Toast.fail(msg || '请求失败')
        //         _reject(result)
        //     }
        // }
    }).catch(err => {
        _reject(err)
    })

    return promise
}

export const get = (params, options) => sendRequest(Object.assign({method: 'GET'}, params), options)

export const post = (params, options) => sendRequest(Object.assign({method: 'POST'}, params), options)
