import { get, post } from './request'
import { GET_API, POST_API } from '../api'

export const login = (params, options) => post({
  url: POST_API.login,
  data: params
}, options)

export const getUserList = (params, options) => get({
  url: GET_API.getUserList,
  data: params
}, options)

export const getWarningList = (params, options) => get({
    url: GET_API.getWarningList,
    data: params
}, options)

export const getThreshold = (params, options) => get({
    url: GET_API.getThreshold,
    data: params
}, options)

export const getDeviceList = (params, options) => get({
    url: GET_API.getDeviceList,
    data: params
}, options)

export const addDevice = params => post({
    url: POST_API.addDevice,
    data: params
})

export const removeDevice = params => post({
    url: POST_API.removeDevice,
    data: params
})

export const updateDevice = params => post({
    url: POST_API.updateDevice,
    data: params
})

export const deleteUser = id => post({
    url: POST_API.deleteUser,
    data: {id}
})

export const modifyUser = params => post({
    url: POST_API.modifyUser,
    data: params
})

export const addUser = params => post({
    url: POST_API.register,
    data: params
})

export const getCompanyList = params => get({
    url: GET_API.getCompanyList,
    data: params
})

export const addCompany = params => post({
    url: POST_API.addCompany,
    data: params
})

export const deleteCompany = params => post({
    url: POST_API.deleteCompany,
    data: params
})

export const editCompany = params => post({
    url: POST_API.editCompany,
    data: params
})
