const URL_PREFIX = 'https://sssw.work:3000'
export const BASE_URL = process.env.NODE_ENV === 'production' ? URL_PREFIX : '/api'

export const GET_API = {
    getUserList: '/user/list',
    getWarningList: '/warning/list',
    getThreshold: '/device/threshold',
    getDeviceList: '/device/list',
    getCompanyList: '/company/list',
}

export const POST_API = {
    login: '/user/admin',
    register: '/user/register',
    deleteUser: '/user/delete',
    modifyUser: '/user/modify',
    updateDevice: '/device/update',
    addDevice: '/device/add',
    removeDevice: '/device/remove',
    addCompany: '/company/add',
    deleteCompany: '/company/delete',
    editCompany: '/company/edit',
}
